<template>
    <v-container>
        <v-data-table
            dense
            :headers="headers"
            :items="printers"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Printers</v-toolbar-title>
                    <v-divider inset vertical class="mx-4"></v-divider>
                    <v-btn :to="{name: 'printer_jobs'}" text color="primary">Bekijk opdrachten</v-btn>
                    <v-btn :to="{name: 'printer_zpl'}" text color="primary">Handmatig printen</v-btn>
                    <v-spacer></v-spacer>

                    <v-dialog
                        v-model="dialog"
                        max-width="500px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="success"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                Printer toevoegen
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>

                                    <v-text-field
                                        v-model="editedItem.name"
                                        label="Naam"
                                    ></v-text-field>

                                    <v-select
                                        label="Verbindingstype"
                                        v-model="editedItem.type"
                                        :items="printerTypes"
                                        item-value="key"
                                        item-text="label"
                                    ></v-select>
                                    <v-text-field
                                        v-model="editedItem.ipAddress"
                                        label="IP-Adres"
                                        :disabled="!printerConfig || !printerConfig.requiresIpAddress"
                                    ></v-text-field><!-- TODO: IP validatie -->
                                    <v-text-field
                                        v-model="editedItem.port"
                                        label="Poort"
                                        :disabled="!printerConfig || !printerConfig.requiresPort"
                                    ></v-text-field><!-- TODO: Poort validatie -->

                                </v-container>

                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="close"
                                >
                                    Annuleren
                                </v-btn>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="save"
                                >
                                    Opslaan
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="headline">Weet je zeker dat je deze printer wilt verwijderen?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDelete">Annuleren</v-btn>
                                <v-btn color="error" text @click="deleteItemConfirm">Verwijderen</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>

            <template v-slot:item.address="{ item }">
                <code>{{item.ipAddress}}:{{item.port}}</code>
            </template>
            <template v-slot:item.type="{ item }">
                {{printerTypesMap[item.type] ? printerTypesMap[item.type].label : item.type}}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
                >
                    fal fa-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteItem(item)"
                >
                    fal fa-trash
                </v-icon>
            </template>
        </v-data-table>

        <v-sheet v-if="isZebraPrintingExtensionAvailable">
            <v-btn
                href="https://chrome.google.com/webstore/detail/zebra-printing/ndikjdigobmbieacjcgomahigeiobhbo?authuser=0"
                target="_blank"
                :color="hasZebraPrintingExtensionInstalled ? 'default' : 'warning'"
                tile
                depressed
                small
            >
                <v-icon left>
                    fab fa-chrome
                </v-icon>
                Zebra Printing (Chrome Extension)
            </v-btn>
        </v-sheet>
    </v-container>
</template>

<script>
import printerTypes from '@/utils/printerTypes';
import interop from "@interop/index";

export default {
    name: "PrinterConfig",
    mounted() {
        this.printerTypes = Object.keys(printerTypes)
            .filter(key => interop.availablePrinterTypes.includes(key))
            .map(key => {
            return {
                key: key,
                label: printerTypes[key].label
            }
        })
        this.defaultItem.type = this.printerTypes[0].key;
    },
    data: () => ({
        headers: [
            {text: 'Naam', value: 'name'},
            {text: 'Adres', value: 'address'},
            {text: 'Type', value: 'type'},
            {text: 'Acties', value: 'actions', sortable: false},
        ],
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {},
        defaultItem: {
            name: '',
            ipAddress: '',
            port: 9001,
            type: 'tcp_socket'
        },
        printerTypes: [],
        printerTypesMap: printerTypes
    }),
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Nieuwe printer' : 'Printer bewerken'
        },
        printers () {
            return this.$store.state.printers.printers;
        },
        printerConfig () {
            let selectedTypeKey = this.editedItem.type;
            return selectedTypeKey ? printerTypes[selectedTypeKey] :  null;
        },
        isZebraPrintingExtensionAvailable() {
            return interop.availablePrinterTypes.includes('chrome_extension')
        },
        hasZebraPrintingExtensionInstalled() {
            return this.$store.state.printers.zebraPrintingExtensionReady;
        }
    },
    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },

    methods: {

        editItem(item) {
            this.editedIndex = this.printers.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.printers.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.$store.dispatch('deletePrinter', this.editedItem);
                this.editedIndex = -1
            })
            //this.printers.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
        },

        save() {
            if (this.editedIndex > -1) {
                this.$store.commit('UPDATE_PRINTER', { index: this.editedIndex, printer: this.editedItem});
                //Object.assign(this.printers[this.editedIndex], this.editedItem)
            } else {
                this.$store.dispatch('addPrinter', this.editedItem);
            }
            this.close()
        },
    },
}
</script>

<style scoped>

</style>
