<template>
    <v-container>
        <v-data-table
            dense
            :headers="headers"
            :items="jobs"
        >

            <template v-slot:item.createdAt="{ item }">
                {{item.createdAt|luxon({input: 'millis', output: 'meds'})}}
            </template>
            <template v-slot:item.label="{ item }">
                <span v-if="item.label">{{item.label.name}}</span>
            </template>
            <template v-slot:item.printer="{ item }">
                {{getPrinterName(item.printerId)}}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    @click="deleteJob(item)"
                >
                    fal fa-trash
                </v-icon>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
export default {
    name: "PrintJobs",
    mounted () {
        this.loadJobs();
    },
    data: () => ({
        headers: [
            {text: 'Datum/tijd', value: 'createdAt'},
            {text: 'Label', value: 'label'},
            {text: 'Aantal', value: 'quantity'},
            {text: 'Printer', value: 'printer'},
            {text: 'Status', value: 'status'},
            {text: 'Acties', value: 'actions', sortable: false},
        ],
        jobs: []
    }),
    methods: {
        loadJobs () {
            this.$store.dispatch('loadPrintJobs').then(jobs => {
                console.log(jobs);
                this.jobs = jobs;
            })
        },
        getPrinterName(printerId) {
            return this.$store.getters.printerById(printerId).name;
        },
        deleteJob(job) {
            this.$store.dispatch('deletePrintJob', job);
        }
    }
}
</script>

<style scoped>

</style>
