<template>
    <v-container>
        <v-card>
            <v-card-text>
                <v-textarea
                    class="code"
                    label="ZPL Code"
                    v-model="zpl"
                    filled
                    required
                    clearable
                    rows="15"
                ></v-textarea>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    :disabled="!selectedPrinter || !zpl || zpl.trim().length === 0"
                    @click.prevent="printZpl"
                >Print
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-card class="mt-4">
            <v-card-title>Preview</v-card-title>
            <v-card-text>
                <div class="row">
                    <div class="col">
                        <v-select
                            label="dp/mm"
                            :items="['6dpmm', '8dpmm', '12dpmm', '24dpmm']"
                            v-model="previewDpmm"
                        ></v-select>
                    </div>
                    <div class="col">
                        <v-text-field
                            label="Breedte (mm)"
                            v-model="previewWidth"
                            type="number"
                        />
                    </div>
                    <div class="col">
                        <v-text-field
                            label="Hoogte (mm)"
                            v-model="previewHeight"
                            type="number"
                        />
                    </div>
                    <div class="col">
                        <v-btn
                            @click="enablePreview()"
                            text
                            color="secondary"
                            :loading="isLoadingPreview"
                        >Redraw
                        </v-btn>
                    </div>
                </div>

                <div v-if="hasLoadedPreview">
                    <img :src="zplImageBase64" v-if="zplImageBase64">
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
let updateTimer;

export default {
    name: "ZplDesigner",
    mounted() {
        this.$store.commit('SET_HEADER', {
            title: 'ZPL-Designer',
            headerMode: this.$route.meta.headerMode,
            isPrinterSelectionVisible: true,
            isRefreshButtonVisible: false
        })
    },
    data: () => ({
        zpl: '',
        previewDpmm: '8dpmm',
        previewWidth: 100,
        previewHeight: 150,
        previewEnabled: false,
        isLoadingPreview: false,
        hasLoadedPreview: false,
        zplImageBase64: null
    }),
    computed: {
        selectedPrinter() {
            return this.$store.getters.defaultPrinter;
        }
    },
    watch: {
        // zpl() {
        //     this.queueUpdateImagePreview();
        // },
        // previewDpmm() {
        //     this.queueUpdateImagePreview();
        // },
        // previewWidth() {
        //     this.queueUpdateImagePreview();
        // },
        // previewHeight() {
        //     this.queueUpdateImagePreview();
        // },
    },
    methods: {
        printZpl() {
            this.$store.dispatch('addPrintJobZpl', {
                printer: this.selectedPrinter,
                quantity: 1,
                zpl: JSON.stringify(this.zpl),
                label: null
            })
        },
        enablePreview() {
            this.previewEnabled = true
            this.updateImagePreview()
        },
        queueUpdateImagePreview() {
            if (!this.previewEnabled) {
                return;
            }

            if (updateTimer) {
                window.clearTimeout(updateTimer)
            }

            updateTimer = window.setTimeout(() => {
                this.updateImagePreview()
            }, 1000);
        },
        async updateImagePreview() {
            this.isLoadingPreview = true;

            let width = Math.ceil(this.previewWidth / 25.4);
            let height = Math.ceil(this.previewHeight / 25.4);
            const index = 0;
            let url = `https://api.labelary.com/v1/printers/${this.previewDpmm}/labels/${width}x${height}/${index}`

            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Accept': 'image/png'
                },
                body: this.zpl,
                referrerPolicy: 'no-referrer',
                credentials: 'omit'
            });
            let blob = await response.blob();

            this.zplImageBase64 = URL.createObjectURL(blob);
            console.log(this.zplImageBase64)

            this.isLoadingPreview = false;
            this.hasLoadedPreview = true;
        }
    }
}
</script>

<style scoped>
.code {
    font-family: Consolas, monaco, monospace;
    font-size: 13px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5714px;
}
</style>
